import * as React from "react"
import { Link } from 'gatsby'

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="お探しのページが見つかりません" />
    <div id="pageTitle">
      <h1>
        お探しのページが見つかりません
      </h1>
    </div>
    <div className="breadClumb">
      <ul>
        <li><Link to="/">HOME</Link></li>
        <li>お探しのページが見つかりません</li>
      </ul>
    </div>
    <div id="main">
      <div className="contents">
        <section className="contents_block">
          <p className="mb50">お探しのページは一時的にアクセスができない状況にあるか、移動、もしくは削除された可能性があります。<br />
            URLをご確認の上再度アクセスいただくか、サイトマップから該当するページをお探しください。</p>
          <p className="mb50"><Link to="/" className="btn_rounded">トップページ</Link></p></section>
      </div>
    </div>
  </>
)

export default NotFoundPage
